import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "layout";
import BlogIndex from "components/BlogIndex/BlogIndex";
import SEO from "components/SEO/SEO";
import config from "../../data/SiteConfig";

class Blog extends React.Component {
  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;
    return (
      <Layout>
        <div className="index-container">
          <Helmet title={config.siteTitle} />
          <SEO />
          <BlogIndex postEdges={postEdges} />
        </div>
      </Layout>
    );
  }
}

export default Blog;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [fields___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
          timeToRead
          frontmatter {
            title
            date
          }
        }
      }
    }
  }
`;
